<template>
  <v-container class="mx-auto my-3 container-width">
    <div
      class="d-flex flex-row align-content-center justify-space-between mb-4"
    >
      <div>
        <p class="primary--text text--sm">Welcome to mPawer</p>
        <h4 class="text-h5 font--500">Hi {{ user.name }}!</h4>
      </div>
      <Button dark color="primary" @click="addNewPet">Add New Animal</Button>
    </div>

    <v-tabs v-model="tab" background-color="transparent">
      <v-tabs-slider color="primary"></v-tabs-slider>
      <v-tab v-for="item in tabItems" :key="item">
        {{ item }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab" background-color="transparent">
      <v-tab-item>
        <PetList
          type="active"
          :loading="loading.pets"
          :pets="activeList"
          @edit-pet="editPet"
          @add-new-pet="addNewPet"
        />
      </v-tab-item>
      <v-tab-item>
        <PetList
          type="adoption"
          :loading="loading.pets"
          :pets="adoptionList"
          @edit-pet="editPet"
          @add-new-pet="addNewPet"
        />
      </v-tab-item>
      <v-tab-item>
        <PetList
          type="co_adoption"
          :loading="loading.pets"
          :pets="coAdoptionList"
          @edit-pet="editPet"
          @add-new-pet="addNewPet"
        />
      </v-tab-item>
    </v-tabs-items>

    <CreateEditPet
      v-model="openDialog"
      :editMode="editMode"
      :key="openDialog"
    />

    <div class="catty-image">
      <img src="@/assets/images/cat.png" alt="Caty" />
    </div>
  </v-container>
</template>

<script>
import { CreateEditPet } from "@/components";
import PetList from "@/components/pet/pet-list";
import { SELLER_STATUS } from "@/constants";
import { createNamespacedHelpers } from "vuex";
const petModule = createNamespacedHelpers("pet");
const userModule = createNamespacedHelpers("user");

export default {
  name: "pet",

  components: {
    CreateEditPet,
    PetList,
  },

  data() {
    return {
      openDialog: false,
      editMode: false,
      tab: null,
    };
  },
  computed: {
    ...petModule.mapState([
      "activeList",
      "adoptionList",
      "coAdoptionList",
      "pet",
      "loading",
    ]),
    ...userModule.mapState(["user"]),
    tabItems() {
      return ["Active", "Adoption", "Co Adoption"];
    },
  },

  methods: {
    ...petModule.mapActions(["FETCH_PET_LIST"]),
    ...petModule.mapMutations([
      "ADD_PET_TO_EDIT",
      "RESET_CREATE_PET_OBJ",
      "SET_CREATE_PET",
    ]),
    editPet(pet) {
      this.ADD_PET_TO_EDIT(pet);
      this.editMode = true;
      this.openDialog = true;
    },
    addNewPet() {
      if (this.user.status === SELLER_STATUS.APPROVED) {
        this.openDialog = true;
      } else {
        this.$notify({
          group: "notification",
          title: "Some Error",
          text: "You are not allowed to create pet until admin approve you.",
          type: "error",
          duration: 5000,
        });
      }
    },
  },

  created() {
    this.FETCH_PET_LIST();
    // let arr = ['city' , 'country' , 'postal_code' , 'latitude' , 'longitude'];
    const {
      city,
      country,
      address,
      postal_code,
      latitude,
      longitude,
    } = this.user;
    this.SET_CREATE_PET({ key: "city", value: city });
    this.SET_CREATE_PET({ key: "country", value: country });
    this.SET_CREATE_PET({ key: "address", value: address });
    this.SET_CREATE_PET({ key: "postal_code", value: postal_code });
    this.SET_CREATE_PET({ key: "latitude", value: Number(latitude) });
    this.SET_CREATE_PET({ key: "longitude", value: Number(longitude) });
  },
  watch: {
    openDialog(val) {
      if (!val) {
        this.editMode = false;
        this.RESET_CREATE_PET_OBJ();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.primary--text {
  margin-bottom: 2px;
}
.thumbnail {
  width: 56px;
  height: 56px;
}
.v-application p {
  margin-bottom: 0;
}
.catty-image {
  position: absolute;
  right: 10px;
  bottom: 10px;
  margin-top: 2rem;
}
.v-sheet.v-card {
  border-radius: 8px !important;
}

.container-width {
  max-width: 1000px;
  width: 90%;

  .theme--light.v-tabs-items {
    background-color: transparent;
  }

  .v-window-item.v-window-item--active {
    padding-top: 1.5rem;
  }

  .v-tab {
    font-family: sans-serif;
    text-transform: capitalize;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
  }
}
</style>
