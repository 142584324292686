<template>
  <div>
    <PetLoader v-if="loading" />
    <template v-else>
      <div v-if="pets.length">
        <PetCard
          v-for="pet in pets"
          :key="pet.id"
          :pet="pet"
          @edit-pet="editPet"
        />
      </div>

      <PetEmptyState @add-pet="addNewPet" v-else />
    </template>
  </div>
</template>

<script>
import PetCard from "./pet-list-card.vue";
import PetLoader from "./pet-loader.vue";
import PetEmptyState from "./pet-empty-state.vue";
export default {
  name: "pet-list",
  components: { PetCard, PetLoader, PetEmptyState },
  props: {
    pets: { type: Array, default: () => [] },
    type: { type: String, default: "Active" },
    loading: { type: Boolean, default: false },
  },
  methods: {
    editPet(pet) {
      this.$emit("edit-pet", pet);
    },
    addNewPet() {
      this.$emit("add-new-pet");
    },
  },
};
</script>

<style lang="scss" scoped></style>
